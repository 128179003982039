import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BackButton from "../../components/backButton/BackButton";
import Theme from "../../components/theme/Theme";
import * as themeService from "../../services/themeService";
import * as courseService from "../../services/courseService";
import { translate } from "../../translations";
import Illustration from "../../components/student/illustration";
import { Helmet } from "react-helmet";

const Themes = () => {
  const [themeData, setThemeData] = useState([]);
  const [course, setCourse] = useState({});
  const { courseId } = useParams();

  useEffect(() => {
    const getThemes = async () => {
      const themes = await themeService.getThemesFromCourse(courseId);
      setThemeData(themes);
    };
    const getCourse = async () => {
      const course = await courseService.getCourse(courseId);
      setCourse(course);
    };
    getCourse();
    getThemes();
  }, []);

  return (
    <Container className="list-view">
      <Helmet>
        <title>{translate("page_title_student_themes")}</title>
      </Helmet>
      <BackButton
        addlClasses="list-view__back"
        buttonText={translate("student_back_to_courses")}
      />
      <div className="list-view__header">
        <h1 className="list-view__title">{course.name}</h1>
        <p className="list-view__count">
          {`${themeData.length} ${translate(
            themeData.length == 1
              ? "student_themes_title_single"
              : "student_themes_title_multiple",
          )}`}
        </p>
      </div>
      <div className="list-view__list">
        {themeData.map((theme) => (
          <Theme {...theme} key={theme._id} />
        ))}
        <Illustration
          name="random"
          className="list-view__list-item list-view__list-item--image"
        />
      </div>
    </Container>
  );
};

export default Themes;
