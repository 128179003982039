import { Link } from "react-router-dom";
import Icon from "../icon";

export default function Challenge({ name, icon, _id, progress, addlClasses }) {
  const classes = [
    addlClasses,
    "challenge",
    (progress?.status == "done" && "challenge--complete") ||
      (progress?.status == "difficulties" && "challenge--difficulties"),
  ];

  return (
    <Link to={_id} className={classes.join(" ")}>
      <svg
        viewBox="0 0 179 156"
        className="challenge__bg"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.3138 70.9169L40.2657 7.91696C42.7789 3.6322 47.3743 1 52.3418 1H126.658C131.626 1 136.221 3.6322 138.734 7.91695L175.686 70.9169C178.251 75.2906 178.251 80.7094 175.686 85.0831L138.734 148.083C136.221 152.368 131.626 155 126.658 155H52.3418C47.3743 155 42.7789 152.368 40.2657 148.083L3.31381 85.0831C0.748507 80.7094 0.748504 75.2906 3.3138 70.9169Z"
          strokeWidth="2"
        />
      </svg>
      <div className="challenge__content">
        <Icon name={`mdi-${icon}`} className="challenge__icon" />
        <p className="challenge__name">{name}</p>
      </div>
    </Link>
  );
}
