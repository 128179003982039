import { useNavigate } from "react-router-dom";
import * as userService from "../../services/authService";
import { USER_ROLES } from "../../utils/constant";

const StudentClassgroup = ({ user }) => {
  const navigate = useNavigate();
  const login = async (e) => {
    e.preventDefault();
    await userService.login(
      { name: user.name, classGroupName: user.classGroup.name },
      USER_ROLES.STUDENT,
    );
    navigate("/courses");
  };

  return (
    <a className="class-card" onClick={login} href="/courses">
      <div className="class-card__content">
        <h2 className="class-card__title">{user.classGroup.name}</h2>
      </div>
      {/* TODO: Add to translations */}
      <p className="class-card__description">Klas gestart op : 10-10-2020</p>
    </a>
  );
};

export default StudentClassgroup;
