import { Link, useParams } from "react-router-dom";
import HexagonProgress from "../hexagonProgress/HexagonProgress";
import { translate } from "../../translations";

const Theme = ({ name, _id, progress, icon }) => {
  const { courseId } = useParams();
  const { completedChallenges, challengesInTheme } = progress;
  const themeComplete = challengesInTheme / completedChallenges;

  return (
    <Link
      className={`theme ${themeComplete == 1 ? "theme--complete" : null}`}
      to={`/courses/${courseId}/${_id}`}
    >
      <HexagonProgress
        themeProgress={progress}
        addlClasses="theme__hexagon"
        icon={icon}
      />

      <div className="theme__text">
        <p className="theme__title">{name}</p>
        <p className="theme__progress">
          {themeComplete == 1
            ? "Thema Afgerond!"
            : `${completedChallenges}/${challengesInTheme} ${translate(
                "challenges",
              )}`}
        </p>
      </div>
      <div className="theme__action">
        <svg
          viewBox="0 0 25 25"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18.627 13.3287L9.13757 22.8181C8.67991 23.2757 7.93792 23.2757 7.4803 22.8181L6.37351 21.7113C5.91663 21.2544 5.91575 20.5139 6.37156 20.056L13.8921 12.5L6.37156 4.9441C5.91575 4.48615 5.91663 3.74567 6.37351 3.28878L7.4803 2.18199C7.93796 1.72433 8.67996 1.72433 9.13757 2.18199L18.627 11.6714C19.0846 12.129 19.0846 12.871 18.627 13.3287Z" />
        </svg>
      </div>
    </Link>
  );
};

export default Theme;
