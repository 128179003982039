import { Container } from "@mui/material";
import { Input } from "@mobidig/components";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { translate } from "../../translations";
import * as authService from "../../services/authService";

export default function StudentRegister() {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    await authService.registerStudent({
      email: data.get("email"),
      password: data.get("password"),
      confirmPassword: data.get("passwordConfirmation"),
    });
    navigate("/login/email");
  };

  return (
    <Container className="login-page login-page--no-logo">
      <Helmet>
        <title>{translate("page_title_student_register")}</title>
      </Helmet>
      <h1 className="login-page__title">Maak een account aan</h1>
      <form onSubmit={handleSubmit} className="form">
        {/* TODO: Show error based on submission and add to translations */}
        {false && (
          <span className="form__error">
            Onjuiste gegevens, probeer opnieuw
          </span>
        )}
        <Input name="email" label="Email adres" type="email" required />
        <Input name="password" label="Wachtwoord" type="password" required />
        <Input
          name="passwordConfirmation"
          label="Herhaal wachtwoord"
          type="password"
          required
        />
        <div className="form__actions">
          <Link className="link link--muted" to={"/courses"}>
            Overslaan
          </Link>
          <button type="submit" className="form__submit button">
            Volgende
          </button>
        </div>
      </form>
    </Container>
  );
}
