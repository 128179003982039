import Icon from "../icon";

const PATH1 = "M22 3L51 3";
const PATH2 = "M22 3H50.389C52.1615 3 53.8015 3.93842 54.6996 5.46655L70 31.5";
const PATH3 =
  "M22 3H50.389C52.1615 3 53.8015 3.93842 54.6996 5.46655L68.511 28.9666C69.4302 30.5305 69.4302 32.4695 68.511 34.0334L53.25 60";
const PATH4 =
  "M22 3H50.389C52.1615 3 53.8015 3.93842 54.6996 5.46655L68.511 28.9666C69.4302 30.5305 69.4302 32.4695 68.511 34.0334L54.6996 57.5334C53.8015 59.0616 52.1615 60 50.389 60H19.75";
const PATH5 =
  "M4 32.9999L18.303 57.5194C19.199 59.0554 20.8435 60 22.6219 60H50.389C52.1615 60 53.8015 59.0616 54.6996 57.5334L68.511 34.0334C69.4302 32.4695 69.4302 30.5305 68.511 28.9666L54.6996 5.46655C53.8015 3.93842 52.1615 3 50.389 3H22";

const HexagonProgress = ({
  themeProgress,
  addlClasses = "",
  icon = "frame_person",
}) => {
  const { completedChallenges, challengesInTheme } = themeProgress;
  const percentage = completedChallenges / challengesInTheme;

  const progressPath = () => {
    if (percentage < 1 / 6) return "";
    else if (percentage < 2 / 6) return PATH1;
    else if (percentage < 3 / 6) return PATH2;
    else if (percentage < 4 / 6) return PATH3;
    else if (percentage < 5 / 6) return PATH4;
    else if (percentage < 1) return PATH5;
    return "";
  };

  const classes = [
    addlClasses,
    "hexagon-progress",
    percentage > 0 && "hexagon-progress--started",
    percentage === 1 && "hexagon-progress--complete",
  ];

  return (
    <div className={classes.join(" ")}>
      <svg
        viewBox="0 0 73 63"
        fill="none"
        className="hexagon-progress__svg"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.48896 34.0335C3.56981 32.4696 3.56981 30.5305 4.48896 28.9666L18.3004 5.46661C19.1985 3.93848 20.8385 3.00006 22.611 3.00006H50.389C52.1615 3.00006 53.8015 3.93849 54.6996 5.46661L68.511 28.9666C69.4302 30.5305 69.4302 32.4696 68.511 34.0335L54.6996 57.5335C53.8015 59.0616 52.1615 60.0001 50.389 60.0001H22.611C20.8385 60.0001 19.1985 59.0616 18.3004 57.5335L4.48896 34.0335Z"
          strokeWidth="6"
          className="hexagon-progress__bg-path"
        />
        <path
          d={progressPath()}
          fill="none"
          strokeWidth="6"
          strokeLinecap="round"
          className="hexagon-progress__fg-path"
        />
      </svg>
      <Icon className="hexagon-progress__icon" name={`mdi-${icon}`} />
    </div>
  );
};

export default HexagonProgress;
