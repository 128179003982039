import { Container } from "@mui/material";
import { Input } from "@mobidig/components";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as authService from "../../services/authService";
import { translate } from "../../translations/index";
import { USER_ROLES } from "../../utils/constant";
import { useUserStore } from "../../stores/user_store";
import Illustration from "../../components/student/illustration";
import { useErrorStore } from "../../stores/error_store";

export default function Login() {
  const { setUser } = useUserStore((state) => ({ setUser: state.setUser }));
  const { errors, clearErrors } = useErrorStore((state) => ({
    errors: state.errors,
    clearErrors: state.clearErrors,
  }));
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const { user } = await authService.login(
      { name: data.get("userName"), classGroupName: data.get("groupName") },
      USER_ROLES.STUDENT,
    );

    if (user) {
      clearErrors();
      setUser(user);
      navigate("/login/email");
    }
  };

  return (
    <Container className="login-page login-page--no-logo">
      <Helmet>
        <title>{translate("page_title_student_login")}</title>
      </Helmet>
      <h1 className="login-page__title">{translate("general_login_title")}</h1>
      <form className="login-page__form form" onSubmit={handleSubmit}>
        {errors.login?.required && (
          <span className="form__error">
            {translate("student_errors_login_required")}
          </span>
        )}
        <Input
          name="groupName"
          label={translate("label_class_name")}
          required
        />
        <Input
          name="userName"
          label={translate("general_login_name")}
          required
        />
        <div className="form__actions">
          <button type="submit" className="button form__submit">
            {translate("student_login_next")}
          </button>
        </div>
      </form>
      <Illustration name="internet" className="login-page__illustration" />
    </Container>
  );
}
