import { useNavigate } from "react-router-dom";

const BackButton = ({ addlClasses = "", buttonText }) => {
  const navigate = useNavigate();

  return (
    <button
      className={addlClasses + " back-button"}
      onClick={() => navigate(-1)}
    >
      <span className="back-button__icon">
        <svg
          viewBox="0 0 26 26"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M25 14C25.5523 14 26 13.5523 26 13C26 12.4477 25.5523 12 25 12L25 14ZM0.292893 12.2929C-0.0976314 12.6834 -0.0976315 13.3166 0.292892 13.7071L6.65685 20.0711C7.04738 20.4616 7.68054 20.4616 8.07107 20.0711C8.46159 19.6805 8.46159 19.0474 8.07107 18.6569L2.41421 13L8.07107 7.34314C8.46159 6.95262 8.46159 6.31946 8.07107 5.92893C7.68054 5.53841 7.04738 5.53841 6.65686 5.92893L0.292893 12.2929ZM25 12L1 12L1 14L25 14L25 12Z" />
        </svg>
      </span>
      <span className="back-button__text">{buttonText}</span>
    </button>
  );
};

export default BackButton;
