import { Container } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as quizService from "../../services/quizService";
import { translate } from "../../translations";

const Quiz = () => {
  const [question, setQuestion] = useState("");
  const [answers, setAnswers] = useState([]);

  const navigate = useNavigate();

  const { quizId, teacherId } = useParams();

  const defaultAnswers = {
    DEFAULT: ["A", "B", "C", "D"],
    EVALUATION: ["😄", "😌", "😐", "😣"],
  };

  const answerQuiz = async (answer, index) => {
    await quizService.answerQuiz(quizId, teacherId, answer, index);
    navigate(-1);
  };

  useEffect(() => {
    if (quizId === "DEFAULT" || quizId === "EVALUATION") {
      setAnswers(defaultAnswers[quizId]);
    } else {
      const getQuiz = async () => {
        const quiz = await quizService.getQuiz(quizId);
        setAnswers(quiz.answers);
        setQuestion(quiz.question);
      };
      getQuiz();
    }
  }, []);

  const optionClasses = [
    "option",
    quizId === "DEFAULT" && "option--default",
    quizId === "EVALUATION" && "option--evaluation",
  ].filter((a) => a);

  return (
    <Container className="quiz">
      <Helmet>
        <title>{translate("page_title_student_quiz")}</title>
      </Helmet>
      <h1 className="quiz__title">{translate("student_quiz_title_quiz")}</h1>
      <label htmlFor="quiz" className="quiz__question">
        {question}
      </label>
      <div className="quiz__options">
        {answers.map((answer, index) => (
          <div className={optionClasses.join(" ")} key={"quiz" + index}>
            <input
              type="radio"
              name="quiz"
              id={`quiz-${index}`}
              className="option__input"
              onChange={() => answerQuiz(answer, index)}
              disabled={false}
            />
            <label htmlFor={`quiz-${index}`} className="option__label">
              {answer}
            </label>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Quiz;
