import { translate } from "../../translations";
import { necessities } from "../../utils";
import Icon from "../icon";

const Necessity = ({ name }) => {
  const necessity = necessities.find((n) => n.value === name);

  return (
    <li className="necessity">
      <Icon name={necessity.icon} className="necessity__icon" />
      <span className="necessity__name">{translate(necessity.label)}</span>
    </li>
  );
};

export default Necessity;
