import appvraag from "../assets/illustrations/appvraag.svg?raw";
import cloud from "../assets/illustrations/cloud.svg?raw";
import community from "../assets/illustrations/community.svg?raw";
import computerLike from "../assets/illustrations/computer_like.svg?raw";
import fishing from "../assets/illustrations/fishing.svg?raw";
import internet from "../assets/illustrations/internet.svg?raw";
import photovideo from "../assets/illustrations/photovideo.svg?raw";
import scoren from "../assets/illustrations/scoren.svg?raw";
import settings from "../assets/illustrations/settings.svg?raw";
import swipe from "../assets/illustrations/swipe.svg?raw";
import taart from "../assets/illustrations/taart.svg?raw";
import videocall from "../assets/illustrations/videocall.svg?raw";
import walkingTheMail from "../assets/illustrations/walking_the_mail.svg?raw";
import wifi from "../assets/illustrations/wifi.svg?raw";

export const ILLUSTRATIONS = {
  appvraag,
  cloud,
  community,
  computerLike,
  fishing,
  internet,
  photovideo,
  scoren,
  settings,
  swipe,
  taart,
  videocall,
  walkingTheMail,
  wifi,
};

export const RANDOM_ILLUSTRATION_OPTIONS = [
  "appvraag",
  "cloud",
  "community",
  "computerLike",
  "fishing",
  "internet",
  "photovideo",
  "scoren",
  "settings",
  "swipe",
  "taart",
  "videocall",
  "wifi",
];

export function shuffle(array) {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function getIllustation(name) {
  switch (name) {
    case "random":
      return ILLUSTRATIONS[shuffle(RANDOM_ILLUSTRATION_OPTIONS)[0]];
    case "randomSmall":
      return shuffle([computerLike, community, taart])[0];
    default:
      return ILLUSTRATIONS[name];
  }
}
