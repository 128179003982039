import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Challenge from "../../../components/student/challenge_card";
import * as challengeService from "../../../services/challengeService";
import * as themeService from "../../../services/themeService";
import * as courseService from "../../../services/courseService";
import BackButton from "../../../components/backButton/BackButton";
import { translate } from "../../../translations";
import { RANDOM_ILLUSTRATION_OPTIONS, shuffle } from "../../../utils";
import Illustration from "../../../components/student/illustration";
import { Helmet } from "react-helmet";

const shuffledIllustrations = shuffle(RANDOM_ILLUSTRATION_OPTIONS);

export default function Challenges() {
  const [challenges, setChallenges] = useState([]);
  const [themeName, setThemeName] = useState("");
  const [courseName, setCourseName] = useState("");

  const { themeId, courseId } = useParams();

  useEffect(() => {
    const getChallenges = async () => {
      const response = await challengeService.index(themeId, true);
      setChallenges(response);
    };
    const getTheme = async () => {
      const theme = await themeService.read(themeId);
      setThemeName(theme.name);
    };
    const getCourse = async () => {
      const course = await courseService.getCourse(courseId);
      setCourseName(course.name);
    };
    getChallenges();
    getTheme();
    getCourse();
  }, []);

  const numOfGroups = Math.ceil(challenges.length / 3);
  const groupedChallenges = new Array(numOfGroups)
    .fill("")
    .map((_, i) => challenges.slice(i * 3, (i + 1) * 3));

  return (
    <Container className="list-view list-view--hexagons">
      <Helmet>
        <title>{translate("page_title_student_challenges")}</title>
      </Helmet>
      <BackButton
        addlClasses="list-view__back"
        buttonText={translate("student_back_to_themes")}
      />
      <p className="list-view__context">{courseName}</p>
      <div className="list-view__header">
        <h1 className="list-view__title">{themeName}</h1>
        <p className="list-view__count">{`${challenges.length} ${translate(
          "challenges",
        )}`}</p>
      </div>
      <div className="list-view__list">
        {groupedChallenges.map((group, index) => (
          <>
            {group.map((challenge) => (
              <Challenge
                {...challenge}
                addlClasses="list-view__list-item"
                key={challenge._id}
              />
            ))}

            <Illustration
              key={`illustrations-${index}`}
              name={shuffledIllustrations[index]}
              className="list-view__list-item list-view__list-item--image"
            />
          </>
        ))}
      </div>
    </Container>
  );
}
