import { BrowserRouter, Routes, Route } from "react-router-dom";

import "../styles/student.css";
import { SocketContext, socket } from "../context/socketContext";
import ProtectedRoutes from "../components/protected_routes";

import Challenges from "../views/student/challenges/index.jsx";
import ChallengeDetails from "../views/student/challenges/show.jsx";
import StudentHome from "../views/student/Home.jsx";
import Themes from "../views/student/Themes.jsx";
import Courses from "../views/student/Courses.jsx";
import StudentLogin from "../views/student/Login";
import Header from "../components/student/header.jsx";
import Quiz from "../views/student/Quiz";
import ContextComponent from "../components/contextComponent/ContextComponent";
import { USER_ROLES } from "../utils/constant";
import StudentLoginEmail from "../views/student/LoginEmail";
import StudentRegister from "../views/student/Register";
import Classgroups from "../views/student/Classgroups";
import OrganisationLoader from "../components/organisation_loader";

export default function StudentApp() {
  return (
    <OrganisationLoader>
      <SocketContext.Provider value={socket}>
        <BrowserRouter>
          <Header />
          <ContextComponent />
          <main id="student">
            <Routes>
              {/* UNPROTECTED ROUTES */}
              <Route path="/" index element={<StudentHome />} />
              <Route path="/register" element={<StudentRegister />} />
              <Route path="/login" element={<StudentLogin />} />
              <Route path="/login/email" element={<StudentLoginEmail />} />
              <Route path="/classes" element={<Classgroups />} />

              {/* STUDENT ROUTES */}
              <Route
                element={<ProtectedRoutes requiredRole={USER_ROLES.STUDENT} />}
              >
                <Route path="/courses/" element={<Courses />} />
                <Route path="/courses/:courseId" element={<Themes />} />
                <Route
                  path="/courses/:courseId/:themeId"
                  element={<Challenges />}
                />
                <Route
                  path="/courses/:courseId/:themeId/:challengeId"
                  element={<ChallengeDetails />}
                />
                <Route path="/quiz/:quizId/:teacherId" element={<Quiz />} />{" "}
                {/* Maybe move to unprotected? */}
              </Route>
            </Routes>
          </main>
        </BrowserRouter>
      </SocketContext.Provider>
    </OrganisationLoader>
  );
}
