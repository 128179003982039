import { Container } from "@mui/material";
import { Link } from "react-router-dom";
import Illustration from "../../components/student/illustration";
import { useOrganisationStore } from "../../stores/organisation_store";
import { translate } from "../../translations";
import { getOrganisationLogo } from "../../utils";
import { Helmet } from "react-helmet";
import logoMobidig from "../../assets/logos/logo_mobidig.png";

export default function Home() {
  const organisation = useOrganisationStore((s) => s.organisation);

  return (
    <Container className="login-page">
      <Helmet>
        <title>{translate("page_title_student_home")}</title>
      </Helmet>
      <img
        src={organisation?.logoURL || getOrganisationLogo() || logoMobidig}
        alt={translate("branding_header_logo", {
          organisationName: organisation.name,
        })}
        className="login-page__logo login-page__logo--big"
      />
      <h1 className="login-page__title">
        {translate("student_home_title_welcome")} <br />
        <span className="login-page__subtitle">
          {translate("student_home_title_to_mobidig")}
        </span>
      </h1>

      <Link className="login-page__cta button button--block" to="/login">
        {translate("student_home_button_start_course")}
      </Link>

      <p className="login-page__secundary-instructions">
        {translate("student_home_text_email_available")}
        <br />
        <Link className="link" to="/login/email">
          {translate("student_home_link_email_login")}
        </Link>
      </p>
      <Illustration name="cloud" className="login-page__illustration" />
    </Container>
  );
}
