import { Link } from "react-router-dom";
import { backendURL } from "../../services";
import CourseProgressBar from "../courseProgressBar/CourseProgressBar";
import { translate } from "../../translations/index";
import placeholder from "../../assets/placeholder.png";

export default function CourseCard({ course }) {
  return (
    <Link className="course" to={course._id}>
      <div className="course__upper">
        <img
          src={
            course.image?.length > 0
              ? `${backendURL}/images/${course.image}`
              : placeholder
          }
          className="course__image"
          alt={translate("course_image_alt_text", { courseName: course.name })}
        />
      </div>
      <div className="course__content">
        <span className="course__title">{course.name}</span>
        <span className="course__description">{course.description}</span>
      </div>
      <div className="course__progress">
        <CourseProgressBar
          value={Math.floor(
            (course.progress.completedChallenges /
              course.progress.totalChallenges) *
              100,
          )}
        />
      </div>
    </Link>
  );
}
