import { Container } from "@mui/material";
import { Input } from "@mobidig/components";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import * as authService from "../../services/authService";
import { USER_ROLES } from "../../utils/constant";
import { translate } from "../../translations";
import { useUserStore } from "../../stores/user_store";

export default function StudentLoginEmail() {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);
    const student = await authService.login(
      { email: data.get("email"), password: data.get("password") },
      USER_ROLES.STUDENT,
    );
    if (student.account.userIds.length > 1) {
      navigate(`/classes?account=${student.account._id}`);
    } else {
      navigate(`/courses`);
    }
  };

  return (
    <Container className="login-page login-page--no-logo">
      <Helmet>
        <title>{translate("page_title_student_login_email")}</title>
      </Helmet>
      <h1 className="login-page__title">{translate("general_login_title")}</h1>
      <form onSubmit={handleSubmit} className="login-page__form form">
        {false && (
          <span className="form__error">
            {translate("student_login_error")}
          </span>
        )}
        <Input
          name="email"
          label={translate("label_email")}
          type="email"
          required
        />
        <Input
          name="password"
          label={translate("label_password")}
          type="password"
          required
          hint={
            <Link to="/" className="link link--muted">
              {translate("general_login_forgot_password")}
            </Link>
          }
        />

        <hr className="form__divider" />

        {user && (
          <p className="form__instructions">
            {translate("student_login_question_no_account")}
            <br />
            <Link className="link" to="/register">
              {translate("student_login_make_account")}
            </Link>
          </p>
        )}

        <div className="form__actions">
          {user && (
            <Link className="link" to="/courses">
              {translate("student_login_skip")}
            </Link>
          )}

          <button type="submit" className="button form__submit">
            {user
              ? translate("student_login_button_link_account")
              : translate("general_login_button")}
          </button>
        </div>
      </form>
    </Container>
  );
}
