import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { translate } from "../../translations";

import StudentClassgroup from "../../components/studentClassgroup/StudentClassgroup";
import * as userService from "../../services/authService";

const Classgroups = () => {
  const [users, setUsers] = useState([]);

  const [queryParams] = useSearchParams();

  useEffect(() => {
    const getUsers = async () => {
      if (queryParams.get("account")) {
        const users = await userService.getAccountUsers(
          queryParams.get("account"),
        );
        setUsers(users);
      }
    };
    getUsers();
  }, []);

  return (
    <Container className="list-view">
      <Helmet>
        <title>{translate("page_title_student_classes")}</title>
      </Helmet>
      <div className="list-view__header">
        <h1 className="list-view__title">
          {/* TODO: Add to translations */}
          Mijn klassen
        </h1>
        <p className="list-view__count">
          {/* TODO: Add to translations */}
          {users.length} lessen
        </p>
      </div>
      <div className="list-view__list">
        {users.map((user) => (
          <StudentClassgroup user={user} key={user._id} />
        ))}
      </div>
    </Container>
  );
};
export default Classgroups;
