import { useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { SocketContext } from "../../context/socketContext";
import { useUserStore } from "../../stores/user_store";

export default function ContextComponent() {
  const socket = useContext(SocketContext);
  const user = useUserStore((s) => s.user);
  const navigate = useNavigate();

  const handleSocketUpdate = useCallback(({ type, data }) => {
    if (type === "QUIZ_STARTED") {
      navigate(`/quiz/${data.quizId}/${data.sessionId}`);
    }
  }, []);

  useEffect(() => {
    if (user === null) return;

    socket.emit("subscribe", user.classgroup);
    socket.on(`${user.classgroup}-update`, handleSocketUpdate);

    return () => socket.off(`${user.classgroup}-update`, handleSocketUpdate);
  }, [user]);

  return <></>;
}
