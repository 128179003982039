import { getIllustation } from "../../utils/illustrations";

export default function Illustration({ name, className }) {
  return (
    <span
      className={`illustration ${className}`}
      dangerouslySetInnerHTML={{ __html: getIllustation(name) }}
    ></span>
  );
}
