import { getOrganisationLogo } from "../../utils";
import { useLocation, Link } from "react-router-dom";
import Illustration from "./illustration";
import { translate } from "../../translations";
import { USER_ROLES } from "../../utils/constant";
import { useUserStore } from "../../stores/user_store";
import { useOrganisationStore } from "../../stores/organisation_store";
import logoMobidig from "../../assets/logos/logo_mobidig.png";

export default function Header() {
  const organisation = useOrganisationStore((s) => s.organisation);
  const { user, removeUser } = useUserStore((state) => ({
    user: state.user,
    removeUser: state.removeUser,
  }));

  let location = useLocation();

  const logout = () => {
    localStorage.removeItem("token");
    removeUser();
    window.location.href = "/";
  };

  if (location.pathname === "/") return;

  return (
    <header className="header">
      <div className="header__upper">
        <div className="header__home">
          <Link to="/" className="">
            <img
              src={
                organisation?.logoURL || getOrganisationLogo() || logoMobidig
              }
              alt={translate("branding_header_logo", {
                organisationName: organisation.name,
              })}
              className="header__logo"
            />
          </Link>
        </div>
        {localStorage.getItem("token") ? (
          <button onClick={logout} className="header__logout">
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              className="header__logout-icon"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M3 19C2.45 19 1.979 18.8043 1.587 18.413C1.19567 18.021 1 17.55 1 17V3C1 2.45 1.19567 1.979 1.587 1.587C1.979 1.19567 2.45 1 3 1H10V3H3V17H10V19H3ZM14 15L12.625 13.55L15.175 11H7V9H15.175L12.625 6.45L14 5L19 10L14 15Z" />
            </svg>
            <span>{translate("logout")}</span>
          </button>
        ) : null}
      </div>
      {user?.role == USER_ROLES.STUDENT && (
        <div className="header__lower">
          <p className="header__message">
            {translate("hello")}
            <br /> <span className="header__username">{user.name}</span>
          </p>
          <Illustration name="walkingTheMail" className="header__img" />
        </div>
      )}
    </header>
  );
}
