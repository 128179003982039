import { Container } from "@mui/material";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import CourseCard from "../../components/student/course_card";
import * as courseService from "../../services/courseService";
import { translate } from "../../translations";
import BackButton from "../../components/backButton/BackButton";

const Courses = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const getCourses = async () => {
      const courseData = await courseService.getCoursesInClass();
      setCourses(courseData.courses);
    };
    getCourses();
  }, []);

  return (
    <Container className="list-view">
      <Helmet>
        <title>{translate("page_title_student_courses")}</title>
      </Helmet>
      {localStorage.getItem("account") && (
        <BackButton
          addlClasses="list-view__back"
          buttonText={translate("student_back_to_classes")}
        />
      )}
      <div className="list-view__header">
        <h1 className="list-view__title">
          {translate("student_courses_title")}
        </h1>
        <p className="list-view__count">
          {courses.length} {translate("courses")}
        </p>
      </div>
      <div className="list-view__list">
        {courses.map((course) => (
          <CourseCard course={course} key={course._id} />
        ))}
      </div>
    </Container>
  );
};

export default Courses;
