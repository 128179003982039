import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Necessity from "../../../components/necessity/Necessity";
import ChallengeComplete from "../ChallengeComplete";
import * as challengeService from "../../../services/challengeService";
import * as progressService from "../../../services/progressService";
import * as socketService from "../../../services/socketService";
import BackButton from "../../../components/backButton/BackButton";
import FeedbackModal from "../../../components/feedbackModal/FeedbackModal";
import { translate } from "../../../translations";
import { PROGRESS_STATUS } from "../../../utils/constant";
import { Helmet } from "react-helmet";

const ChallengeDetails = () => {
  const [challenge, setChallenge] = useState(null);
  const [challengeStatus, setChallengeStatus] = useState("");
  const [showFeedback, setShowFeedback] = useState(false);

  const params = useParams();
  const { challengeId, themeId, courseId } = params;

  const handleClick = async (status) => {
    setChallengeStatus(status);
    if (status == PROGRESS_STATUS.DONE) setShowFeedback(true);
    await progressService.create({ ...params, status });
  };

  useEffect(() => {
    const getChallenge = async () => {
      const response = await challengeService.getChallenge(challengeId);
      if (response.progress) setChallengeStatus(response.progress.status);
      setChallenge(response);
    };
    getChallenge();
    socketService.viewingChallenge(courseId, themeId, challengeId);
  }, []);

  return (
    challenge && (
      <div className="challenge-details">
        <Helmet>
          <title>{translate("page_title_student_challenge_details")}</title>
        </Helmet>
        <Container className="challenge-details__back">
          <BackButton buttonText={translate("student_back_to_challenges")} />
        </Container>
        <Container className="challenge-details__content">
          <h1 className="challenge-details__title">{challenge.name}</h1>
          <p className="challenge-details__description">
            {challenge.shortDescription}
          </p>
          <h2 className="challenge-details__section-title">
            {translate("student_challenge_details_necessities")}
          </h2>
          <ul className="challenge-details__necessities">
            {challenge.necessities.map((nec) => (
              <Necessity name={nec} key={nec} />
            ))}
          </ul>
          <h2 className="challenge-details__section-title">
            {translate("student_challenge_details_description")}
          </h2>
          {/* TODO: Add styling for elements that are outputted from the WYSIWYG-editor */}
          <div
            className="challenge-details__steps"
            dangerouslySetInnerHTML={{ __html: challenge.description }}
          />

          {showFeedback ? <ChallengeComplete /> : null}
          {showFeedback && (
            <FeedbackModal challengeInfo={{ challengeId, themeId }} />
          )}
        </Container>
        <Container className="challenge-details__actions">
          {challengeStatus == PROGRESS_STATUS.DONE && (
            <span className="challenge-details__completed completed">
              <span className="completed__icon">
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M23.625 12C23.625 18.4203 18.4203 23.625 12 23.625C5.57967 23.625 0.375 18.4203 0.375 12C0.375 5.57967 5.57967 0.375 12 0.375C18.4203 0.375 23.625 5.57967 23.625 12ZM10.6553 18.1553L19.2803 9.53034C19.5732 9.23747 19.5732 8.76258 19.2803 8.4697L18.2197 7.40906C17.9268 7.11614 17.4519 7.11614 17.159 7.40906L10.125 14.443L6.84098 11.159C6.54811 10.8661 6.07322 10.8661 5.7803 11.159L4.71966 12.2197C4.42678 12.5125 4.42678 12.9874 4.71966 13.2803L9.59466 18.1553C9.88758 18.4482 10.3624 18.4482 10.6553 18.1553V18.1553Z" />
                </svg>
              </span>
              <p className="completed__text">
                {translate("student_challenge_details_challenge_completed")}
              </p>
            </span>
          )}

          <>
            {challengeStatus != PROGRESS_STATUS.DIFFICULTIES &&
              challengeStatus != PROGRESS_STATUS.DONE && (
                <button
                  className="challenge-details__difficulties button button--outline button--secondary"
                  onClick={() => handleClick("difficulties")}
                >
                  {translate("student_challenge_details_button_difficulties")}
                </button>
              )}
            {challengeStatus != PROGRESS_STATUS.DONE && (
              <button
                className="challenge-details__difficulties button button--secondary"
                onClick={() => handleClick("done")}
              >
                {translate("student_challenge_details_button_completed")}
              </button>
            )}
          </>
        </Container>
      </div>
    )
  );
};

export default ChallengeDetails;
